import React from 'react';
import Scroll from '../Scroll';

export default function Crecer() {
  return (
    <section className="expertise-section bg-grey pb-5" id="services">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 text-center">
            <h3 className="font-size__l py-5">
              Queremos crecer contigo
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2">
            <p className='font-size__m'>
              Nuestro objetivo es reducir la complejidad de cada proyecto para ampliar el margen destinado al crecimiento del negocio.
            </p>
            <p className='font-size__m'>
              Queremos liberar a tu eCommerce de todo lo que suponga un consumo excesivo de recursos, ya sean económicos 
              o tecnológicos, y acompañarte en la transición hacia un modelo más asequible en todos los sentidos.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
