import React from 'react';

import LayoutEs from '../components/LayoutEs';
import Scroll from '../components/Scroll';
import Footer from '../components/Footer';
import Header from '../components/Header';
import ScrollToTop from '../components/ScrollToTop';
import Contact from '../components/Contact';
import SEO from '../components/Seo';
import Intro from '../components/About/Intro';
import Crecer from '../components/About/Crecer';
import Projects from '../components/Proyectos';
import Clients from '../components/About/Clients';
import Calendly from '../components/Calendly';
import Callout from '../components/Callout';

class Nosotros extends React.Component {
  render() {
    return (
      <LayoutEs>
        <Header />

        <SEO
          title="Sobre nosotros"
          description="Desde 2010 realizando proyectos ecommerce Magento, asesorando a empresas en su estrategia y reduciendo sus costes de operación."
          canonicalUrl="https://www.bekome.digital/nosotros/"
        />

        <header className="masthead about d-flex">
          <div className="container text-center my-auto">
            <h1 className="mb-1 text-primary">Hacemos fácil lo que parece complicado</h1>
            <h4 className='mb-3 h4'>
              A veces, conseguir lo que tu proyecto necesita se traduce en capas y capas de complejidad que repercuten 
              en la agilidad de los procesos y en la cuenta de resultados de tu empresa.
            </h4>
            <h4 className='mb-3 h4'>En Beköme Digital creemos que las cosas pueden ser más simples sin necesidad de perder 
            funcionalidades y allí es donde entra en juego nuestra experiencia.</h4>
            <Scroll type="id" element="start" offset={0}>
              <a className="text-white vert-move" href="#start" title="Sobre nosotros">
                <i className="icon-arrow-down"></i>
              </a>
            </Scroll>
          </div>
          <div className="overlay"></div>
        </header>

        <Intro />

        <Crecer />

        <Callout />

        <Clients />

        <Projects />

        <div className='container-fluid bg-grey'>
          <div className="container text-center text-white">
            <div className="row">
              <div className="col-12 text-secondary">
                <h4 className="font-size__l mt-5">
                  ¿Quieres sacar el máximo rendimiento a tu eCommerce? 
                </h4>
                <p className='font-size__m'>
                  Háblanos de tu proyecto, estamos deseando escucharte.
                </p>
              </div>
            </div>
          </div>
        </div>

        <Contact />

        <ScrollToTop />

        <Footer />

      </LayoutEs>
    );
  }
}

export default Nosotros;
