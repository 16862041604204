import React from 'react';
import Scroll from '../Scroll';

export default function Clients() {
  return (
    <section className="content-section intro-section" id="start">
      <div className="container text-center">
        <div className="row">
          <div className="col-12">
            <h2 className="font-size__l mb-4">
              Te presentamos algunos de nuestros clientes
            </h2>
          </div>
        </div>
      </div>
    </section>
  );
}
