import React from 'react';

export default function Intro() {
  return (
    <section className="content-section" id="start">
      <div className="container">
        <div className="row mt-lg-5">
          <div className="col-12 col-md-6">
            <h2 className="mb-5">
              Soy Manel, consultor eCommerce, especialista en Magento y BigCommerce, y fundador de Beköme Digital.
            </h2>
            <p className="font-size__m">
              Después de haber trabajado como especialista en Magento para diferentes agencias de desarrollo de 
              eCommerce me di cuenta de que aquello no iba conmigo. 
            </p>
            <p className='font-size__m'>
              Yo apuesto por una innovación que permita ahorrar costes a futuro, por ofrecer soluciones reales en lugar 
              de limitarme a cerrar presupuestos y, por encima de todo, creo que cada proyecto requiere una tecnología 
              a su medida, nada de producciones en cadena.
            </p>
            <p className='font-size__m mb-5'>Y por eso nació <span className='text-primary font-weight__700'>Beköme Digital</span></p>

            <a href='https://www.credly.com/badges/96f705d6-340a-4a31-a476-3563ba228c38/public_url'
                target="_blank"
                title="Manel Lavela Adobe Commerce Professional Developer"
            >
              <span className="font-size__m text-primary">Comprueba mi certificación Adobe Commerce</span>
            </a>
            <div className='mt-3'>
              <img
                  src="https://res.cloudinary.com/drjo0cbtf/image/upload/c_scale,f_auto,q_auto,w_200/v1665159623/bekome/adobe-certified-professional-adobe-commerce-developer_2_xyhcrz.png"
                  alt="Manel Lavela Adobe Commerce Professional" width="200"
                />
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <img
              src="https://res.cloudinary.com/drjo0cbtf/image/upload/c_scale,f_auto,q_auto:best,w_700/v1655827181/bekome/baixa_006_lh33kd.jpg"
              alt="Manel Lavela Experto Ecommerce"
              className="img-fluid"
              />
          </div>
        </div>
      </div>
    </section>
  );
}
