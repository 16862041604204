import React from 'react';
import Scroll from '../components/Scroll';

export default function Callout() {
  return (
    <>
      <section className="callout d-flex" id="callout">
        <div className="container text-center my-auto">
          <div className="row">
            <div className="col-12">
              <h2 className="mx-auto text-primary">
                ¿Por qué Beköme?
              </h2>
              <h5 className="lead text-white mb-5">Nuestros valores</h5>
            </div>
            <div className="col-lg-4 col-sm-6 mx-auto mb-4">
              <h4 className="text-primary text-left">Nos involucramos</h4>
              <p className="text-white text-left">
                Tus objetivos son nuestros objetivos.<br />Tus problemas, nuestros retos.<br />Y tu éxito, nuestro éxito. 
                <br />Estamos juntos en esto.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6 mx-auto mb-4">
              <h4 className="text-primary text-left">Creamos relaciones de confianza</h4>
              <p className="text-white text-left">
              Estar cerca de nuestros clientes nos ayuda a mejorar.
              <br />Por eso te invitamos a que nos contactes por teléfono, mail o Whatsapp siempre que lo necesites.
              Aunque el proyecto haya sido entregado.
              </p>
            </div>
          </div>
          <div className="row">
            {/* <h2 className="mx-auto mb-5 text-white d-none">
              Estás a un paso de conocer a tu nueva agencia
            </h2> */}
            <div className="col-lg-4 col-sm-6 mx-auto mb-4">
              <h4 className="text-primary text-left">Nos comprometemos</h4>
              <p className="text-white text-left">
                Si aceptamos un proyecto es porque estamos seguros de realizar un gran trabajo, cumpliendo 
                con las fechas de entrega y la calidad requerida.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6 mx-auto mb-4">
              <h4 className="text-primary text-left">Sabemos lo que hacemos</h4>
              <p className="text-white text-left">
              Nuestro equipo está formado por desarrolladores certificados en Magento 2 y BigCommerce con una amplia 
              experiencia en proyectos de distintos tipos y complejidades.
              </p>
            </div>
          </div>
          <Scroll type="id" element="calendly" offset={0}>
              <a className="btn btn-primary btn-lg" href="#calendly">
                Háblanos de tu proyecto
              </a>
          </Scroll>
        </div>
      </section>
    </>
  );
}
