import React from 'react';
import { PopupButton } from 'react-calendly';

export default function Calendly() {
  return (
    <section className="calendly-section intro-section bg-secondary" id="calendly">
      <div className="container text-center text-white">
        <div className="row">
          <div className="col-12">
            <h4 className="font-size__m mb-5">
              Reserva una consultoría gratuita de 15m
            </h4>
            <PopupButton text="Reservar ahora" className='btn btn-primary-green btn-lg' url="https://calendly.com/bekome-digital/consultoria-gratuita-30m" />
          </div>
        </div>
      </div>
    </section>
  );
}
