import React from 'react';

export default function Logos() {
  return (
    <section className="content-section clients-section" id="start">
      <div className="container mb-5">
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="text-secondary font-size__xl">Proyectos que hemos realizado</h2>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row mb-5">
          {/* ISDIN */}
          <div className="col-xs-12 col-md-3 client-item">
              <img alt="desarrollo ecommerce b2b magento 2"
              src="https://res.cloudinary.com/drjo0cbtf/image/upload/e_grayscale/v1570107663/Clients/c5_usbmbn.png"
              className="img-fluid d-block mx-auto"
              width={180} />
          </div>
          {/* Equivalenza */}
          <div className="col-xs-12 col-md-3 client-item">
              <img alt="desarrollo ecommerce b2c magento 2"
              src="https://res.cloudinary.com/drjo0cbtf/image/upload/f_auto,q_auto/v1652215732/Clients/logoequivalenza_i8clmo.png"
              className="img-fluid d-block mx-auto"
              width={180} />
          </div>
          {/* Fvivo */}
          <div className="col-xs-12 col-md-3 client-item">
              <img alt="desarrollo ecommerce b2c magento"
              src="https://res.cloudinary.com/drjo0cbtf/image/upload/e_grayscale/v1677444277/Clients/farmacias-vivo_vbrmdj.png"
              className="img-fluid d-block mx-auto"
              width={180} />
          </div>
          {/* Milan */}
          <div className="col-xs-12 col-md-3 client-item">
              <img alt="desarrollo ecommerce magento"
              src="https://res.cloudinary.com/drjo0cbtf/image/upload/e_grayscale/c_scale,w_230/v1570107663/Clients/c6_jfttya.png"
              className="img-fluid d-block mx-auto"
              width={180} />
          </div>
        </div>
        <div className='row'>
          {/* Azimut emotion */}
          <div className="col-xs-12 col-md-3 client-item">
              <img alt="desarrollo ecommerce magento 1"
              src="https://res.cloudinary.com/drjo0cbtf/image/upload/c_scale,e_grayscale,w_202/v1677447393/Clients/azimut-emotion_gruwlf.png"
              className="img-fluid d-block mx-auto"
              style={{ margin: '-15px 0 0 0' }}
              width={180} />
          </div>
          {/* Azimut marine */}
          <div className="col-xs-12 col-md-3 client-item">
              <img alt="desarrollo ecommerce magento 1"
              src="https://res.cloudinary.com/drjo0cbtf/image/upload/c_scale,w_204/v1677447393/Clients/logo-azimutmarine_p23yl8.png"
              className="img-fluid d-block mx-auto"
              style={{ margin: '-15px 0 0 0' }}
              width={180} />
          </div>
          {/* Azimut caravaning */}
          <div className="col-xs-12 col-md-3 client-item">
              <img alt="desarrollo ecommerce magento 1"
              src="https://res.cloudinary.com/drjo0cbtf/image/upload/c_scale,w_204/v1677447393/Clients/azimut-caravaning_ka5yk6.png"
              className="img-fluid d-block mx-auto"
              style={{ margin: '-15px 0 0 0' }}
              width={180} />
          </div>
          {/* Azimut onroad */}
          <div className="col-xs-12 col-md-3 client-item">
              <img alt="desarrollo ecommerce magento 1"
              src="https://res.cloudinary.com/drjo0cbtf/image/upload/c_scale,w_204/v1677447393/Clients/logo-azimutonroad_st5rer.png"
              className="img-fluid d-block mx-auto"
              style={{ margin: '-15px 0 0 0' }}
              width={180} />
          </div>
        </div>
        <div className='row'>
          {/* Supermercados Mas */}
          <div className="col-xs-12 col-md-3 client-item">
              <img alt="desarrollo ecommerce magento 2"
              src="https://res.cloudinary.com/drjo0cbtf/image/upload/e_grayscale/v1570107725/Clients/c7_d6qspb.svg"
              className="img-fluid d-block mx-auto greyscale"
              width={180} />
          </div>
          {/* Konokono */}
          <div className="col-xs-12 col-md-3 client-item">
              <img alt="Desarrollo ecommerce magento 2"
              src="https://res.cloudinary.com/drjo0cbtf/image/upload/c_scale,e_grayscale,w_230/v1644354386/Clients/konokono_logo_jprawj.svg"
              className="img-fluid d-block mx-auto"
              style={{ margin: '10px 0 0 0' }}
              width={180} />
          </div>
           {/* fibercom */}
          <div className="col-xs-12 col-md-3 client-item">
              <img alt="desarrollo ecommerce b2b magento 2"
              src="https://res.cloudinary.com/drjo0cbtf/image/upload/c_scale,e_grayscale,q_auto,w_207/v1677447064/Clients/fibercom_ukjzoq.png"
              className="img-fluid d-block mx-auto greyscale"
              width={180} />
          </div>
          {/* casa caracol */}
          <div className="col-xs-12 col-md-3 client-item">
              <img alt="desarrollo ecommerce b2b magento 2"
              src="https://res.cloudinary.com/drjo0cbtf/image/upload/c_scale,e_grayscale,w_234/v1677449469/Clients/casacaracol_sv2v8c.png"
              className="img-fluid d-block mx-auto greyscale"
              width={180} />
          </div>
        </div>
      </div>
    </section>
  );
}
